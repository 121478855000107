<template>
  <div class="box">
    <div class="headline">题库管理</div>
    <div class="second_line">6.2临时题库</div>
    <span class="text">
      进入临时题库界面，【题库管理】→【临时题库】，如下图
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/44.png')"
      :preview-src-list="[require('../../assets/Bankimg/44.png')]"
    >
    </el-image>
    <span class="text"> 该界面直接显示了所有题库的详细信息。 </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/45.png')"
      :preview-src-list="[require('../../assets/Bankimg/45.png')]"
    >
    </el-image>
    <span class="text">
      1.新增：点击“添加题库”，输入《文件编号》、《文件名称》等一些基本信息，按需填题库信息，点击“确定添加”。
      2.修改：从列表选中某一个题库，点击右上角“修改”符号，按需修改题库信息，点击“确定”。
      3.删除：从列表选中某一个题库，点击右上角“删除”符号来删除该题库。
    </span>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      srcList: "require('../../assets/Bankimg/1.jpg')",
    };
  },
};
</script>
  
  <style  lang="less" scoped>
.box {
  padding-left: 40px;
  .headline {
    font-size: 28px;
    color: #1f2d3d;
    padding-top: 28px;
    line-height: 45px;
    padding-left: 0px ;
  }
  .second_line {
    font-size: 24px;
    line-height: 32px;
    padding: 24px 0 12px;
  }
  .text {
    color: #141e31;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 8px;
  }
  img {
  }
}
</style>